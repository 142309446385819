import React from "react";
import { Routes, Route } from "react-router-dom";

import Portfolio from "./pages/Portfolio";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Portfolio />} />
      <Route path="/*" element={<Portfolio />} />
    </Routes>
  );
};

function App() {
  return <Router />;
}

export default App;
