import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../themes/works.css";

const Works = () => {
  const launchWorksPageAnimDesktop = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".WorksPageContainer", {
      top: 0,
      scale: 1,
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".WorksPageContainer",
        scrub: true,
        start: "3200px top",
        end: "+=3250",
      },
    });
    gsap.to(".WorksTitle", {
      left: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksContainer", {
      right: "-200%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksTitle", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
    gsap.to(".WorksContainer", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
  };
  const launchWorksPageAnimTablet = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".WorksPageContainer", {
      top: 0,
      scale: 1,
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".WorksPageContainer",
        scrub: true,
        start: "3200px top",
        end: "+=3250",
      },
    });
    gsap.to(".WorksTitle", {
      left: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksContainer", {
      right: "-400%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksTitle", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
    gsap.to(".WorksContainer", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
  };
  const launchWorksPageAnimLargeScreen = () => {
    gsap.to(".WorksPageContainer", {
      top: 0,
      scale: 1,
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".WorksPageContainer",
        scrub: true,
        start: "3200px top",
        end: "+=3250",
      },
    });
    gsap.to(".WorksTitle", {
      left: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksContainer", {
      right: "-280%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksTitle", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
    gsap.to(".WorksContainer", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
  };
  const launchWorksPageAnimPhone = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".WorksPageContainer", {
      top: 0,
      scale: 1,
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".WorksPageContainer",
        scrub: true,
        start: "3200px top",
        end: "+=3250",
      },
    });
    gsap.to(".WorksTitle", {
      left: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksContainer", {
      right: "-900%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "6000px top",
        end: "+=7000",
      },
    });
    gsap.to(".WorksTitle", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksTitle",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
    gsap.to(".WorksContainer", {
      top: "-100%",
      scrollTrigger: {
        trigger: ".WorksContainer",
        scrub: true,
        start: "13000px top",
        end: "+=3000",
      },
    });
  };

  useEffect(() => {
    if (window.matchMedia("(max-width: 425px)").matches) {
      launchWorksPageAnimPhone();
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      launchWorksPageAnimTablet();
    } else if (window.matchMedia("(max-width: 1024px)").matches) {
      launchWorksPageAnimLargeScreen();
    } else {
      launchWorksPageAnimDesktop();
    }
  }, []);

  return (
    <div>
      <div className="WorksPageContainer">
        <p className="WorksTitle">
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
          <span>PROJETS</span>
          <span className="span">PROJETS</span>
        </p>
        <div className="WorksContainer">
          <div
            className="Project2"
            onClick={() => window.open("https://ilea-coiffure.com/")}
          >
            <p>
              Iléa - Application Mobile de Prise de Rdv pour Coiffeurs à
              domicile
            </p>
          </div>
          <div
            className="Project1"
            onClick={() => window.open("https://github.com/Ozneeee")}
          >
            <p>InvPress - Application Mobile de Gestion d'entreprise</p>
          </div>
          <div
            className="Project3"
            onClick={() =>
              window.open("https://github.com/Ozneeee/tp-react-symfony")
            }
          >
            <p>Vins Montpelliérain - Site e-commerce pour un caviste</p>
          </div>
          <div className="Project4">
            <p>Portfolio Enzo Ruffin - En espérant que vous l'appréciez</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
