import React, { useEffect } from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Background from "../pages/Background";

import "../themes/landing.css";

function LandingDesktop() {
  const landingDesktopAnim = () => {
    gsap.registerPlugin(ScrollTrigger);
    const loadTL = gsap.timeline({ delay: 0.5 });

    loadTL
      .fromTo(".name", { autoAlpha: 0, y: 100 }, { y: 0, autoAlpha: 1 })
      .fromTo(
        ".devWeb",
        { autoAlpha: 0, y: 100 },
        { y: 0, autoAlpha: 1 },
        "=-0.2"
      )
      .fromTo(
        ".devMobile",
        { autoAlpha: 0, y: 100 },
        { y: 0, autoAlpha: 1 },
        "=-0.2"
      )
      .to(".pp", { scale: 1, autoAlpha: 1 })
      .to(".landingBar", { autoAlpha: 1, rotate: 20 }, "=-0.5")
      .to(".linkedin-icon", { autoAlpha: 1, rotate: 15, top: "28%" })
      .to(".github-icon", { autoAlpha: 1, rotate: -15, bottom: "15%" }, "=-0.5")
      .to(".signature", { left: 0 }, "=-0.3");

    gsap.to(".name", {
      right: 2000,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "2500px top",
      },
    });
    gsap.to(".devWeb", {
      left: 2000,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "2500px top",
      },
    });
    gsap.to(".devMobile", {
      right: 2000,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "2500px top",
      },
    });
    gsap.to(".landingBar", {
      width: 0,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "1500px top",
      },
    });
    gsap.to(".linkedin-icon", {
      scale: 0,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "1500px top",
      },
    });
    gsap.to(".github-icon", {
      scale: 0,
      scrollTrigger: {
        scrub: true,
        start: "0px top",
        end: "1500px top",
      },
    });
  };

  useEffect(() => {
    landingDesktopAnim();
  }, []);

  return (
    <div className="landingContainer">
      <Background />
      <div
        onClick={() =>
          window.open("https://www.linkedin.com/in/enzo-ruffin-a13ba0250/")
        }
        data-speed={3}
        className="linkedin-icon opacity-0"
      >
        <FaLinkedin className="iconSize" color="#d8d8d8" />
      </div>
      <div
        onClick={() => window.open("https://github.com/Ozneeee")}
        data-speed={3}
        className="github-icon opacity-0"
      >
        <FaGithub className="iconSize" color="#d8d8d8" />
      </div>
      <div onClick={() => window.scrollTo(0, 0)} className="signature">
        E.R
      </div>
      <div className="title-text-container">
        <div data-speed={3} className="landingBar opacity-0"></div>
        <div>
          <div className="name flex flex-row items-center opacity-0">
            <div data-speed={3} className="pp scale-0 opacity-0">
              <div className="memoji"></div>
            </div>
            <h1 className="ml-10">ENZO RUFFIN</h1>
          </div>
        </div>
        <div className="devWeb opacity-0">
          <h2>DEVELOPPEUR WEB</h2>
        </div>
        <div className="devMobile opacity-0">
          <h2>MOBILE FULLSTACK</h2>
        </div>
      </div>
    </div>
  );
}

export default LandingDesktop;
