import React from "react";
import Lenis from "@studio-freight/lenis";

import Cursor from "../components/Cursor";
import Landing from "./Landing";
import About from "./About";
import Works from "./Works";
import Contact from "./Contact";

function Portfolio() {
  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time / 2);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  return (
    <div className="App">
      <Cursor />
      <Landing />
      <About />
      <Works />
      <Contact />
    </div>
  );
}

export default Portfolio;
