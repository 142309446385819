import React, { useEffect } from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareGithub } from "react-icons/fa6";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Background from "../pages/Background";

import "../themes/landingPhone.css";

function LandingPhone() {
  const landingPhoneAnim = () => {
    const loadTL = gsap.timeline({ delay: 0.5 });
    gsap.registerPlugin(ScrollTrigger);

    loadTL

      .to(".signaturePhone", { top: 0 })
      .to(".iconSizePhone", { top: 0, stagger: 0.2 })
      .to(".ppPhone", { autoAlpha: 1, width: 150, height: 150 })
      .to(".phoneTitles", { autoAlpha: 1, stagger: 0.2 })
      .to(".swipeUP", { autoAlpha: 1, bottom: "-4%" });

    gsap.to(".headerPhone", {
      height: 50,
      scrollTrigger: {
        scrub: true,
        start: "800px top",
        end: "1500px top",
      },
    });
    gsap.to(".iconSizePhone", {
      width: 25,
      height: 25,
      scrollTrigger: {
        scrub: true,
        start: "800px top",
        end: "1500px top",
      },
    });
    gsap.to(".signaturePhone", {
      fontSize: 20,
      scrollTrigger: {
        scrub: true,
        start: "800px top",
        end: "1500px top",
      },
    });
  };

  useEffect(() => {
    landingPhoneAnim();
  }, []);

  return (
    <div className="landingContainer">
      <div className="headerPhone z-10 fixed">
        <div onClick={() => window.scrollTo(0, 0)} className="signaturePhone">
          E.R
        </div>
        <div className="flex flex-row">
          <div
            onClick={() =>
              window.open("https://www.linkedin.com/in/enzo-ruffin-a13ba0250/")
            }
            className="mr-6"
          >
            <FaLinkedin className="iconSizePhone" color="#d8d8d8" />
          </div>
          <div onClick={() => window.open("https://github.com/Ozneeee")}>
            <FaSquareGithub className="iconSizePhone" color="#d8d8d8" />
          </div>
        </div>
      </div>
      <div className="title-text-container">
        <Background />
        <div data-speed={3} className="ppPhone opacity-0 w-0 h-0">
          <div className="memojiPhone"></div>
        </div>
        <div className="text-containerPhone">
          <div>
            <h1 className="phoneTitles opacity-0">ENZO RUFFIN</h1>
          </div>
          <div>
            <h2 className="phoneTitles opacity-0">DEVELOPPEUR WEB</h2>
          </div>
          <div>
            <h2 className="phoneTitles opacity-0">MOBILE FULLSTACK</h2>
          </div>
        </div>
        <div className="swipeUP">
          <div className="swipeUpImg"></div>
          <p>Swipe UP</p>
        </div>
      </div>
    </div>
  );
}

export default LandingPhone;
