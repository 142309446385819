import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../themes/contact.css";

const Contact = () => {
  const launchContactPageAnimDesktop = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".ContactPageContainer", {
      top: "50%",
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".ContactPageContainer",
        scrub: true,
        start: "15000px top",
        end: "+=1500",
      },
    });
    gsap.to(".ContactTitle", {
      top: 0,
      autoAlpha: 1,
      scrollTrigger: {
        trigger: ".ContactTitle",
        scrub: true,
        start: "15500px top",
        end: "+=1500",
      },
    });
    gsap.to(".SendMailBtn", {
      scale: 1,
      scrollTrigger: {
        trigger: ".SendMailBtn",
        start: "16500px top",
        end: "+=500",
        onLeaveBack: () => {
          gsap.to(".SendMailBtn", {
            scale: 0,
          });
        },
        onEnter: () => {
          gsap.to(".SendMailBtn", {
            scale: 1,
          });
        },
      },
    });
    gsap.to(".Footer", {
      top: "90%",
      scrollTrigger: {
        trigger: ".Footer",
        start: "16500px top",
        end: "+=500",
        onLeaveBack: () => {
          gsap.to(".Footer", {
            top: "100%",
          });
        },
        onEnter: () => {
          gsap.to(".Footer", {
            top: "90%",
          });
        },
      },
    });
  };
  const launchContactPageAnimPhone = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".ContactPageContainer", {
      top: "50%",
      background: "#0e0e0e",
      scrollTrigger: {
        trigger: ".ContactPageContainer",
        scrub: true,
        start: "14000px top",
        end: "+=1500",
      },
    });
    gsap.to(".ContactTitle", {
      top: 0,
      autoAlpha: 1,
      scrollTrigger: {
        trigger: ".ContactTitle",
        scrub: true,
        start: "14500px top",
        end: "+=1500",
      },
    });
    gsap.to(".SendMailBtn", {
      scale: 1,
      scrollTrigger: {
        trigger: ".SendMailBtn",
        start: "15500px top",
        end: "+=500",
        onLeaveBack: () => {
          gsap.to(".SendMailBtn", {
            scale: 0,
          });
        },
        onEnter: () => {
          gsap.to(".SendMailBtn", {
            scale: 1,
          });
        },
      },
    });
    gsap.to(".Footer", {
      top: "90%",
      scrollTrigger: {
        trigger: ".Footer",
        start: "15500px top",
        end: "+=500",
        onLeaveBack: () => {
          gsap.to(".Footer", {
            top: "100%",
          });
        },
        onEnter: () => {
          gsap.to(".Footer", {
            top: "90%",
          });
        },
      },
    });
  };

  useEffect(() => {
    if (window.matchMedia("(max-width: 425px)").matches) {
      launchContactPageAnimPhone();
    } else {
      launchContactPageAnimDesktop();
    }
  }, []);

  return (
    <div>
      <div className="ContactPageContainer"></div>
      <div className="ContactTitle">
        <p>
          <span>C</span>
          <span>O</span>
          <span>N</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>T</span>
        </p>
      </div>
      <div className="SendMailBtnContainer">
        <div
          className="SendMailBtn"
          onClick={() => window.open("mailto:enzo.ruffin.pro@gmail.com")}
        >
          <div className="size-4 bg-[#0e0e0e] rounded-full"></div>
          <p>M'envoyer un mail</p>
        </div>
      </div>
      <div className="Footer">
        <p>© Designé et Développé par Enzo Ruffin - 2024</p>
        <p>
          <span
            className="cursorBig"
            onClick={() => window.open("https://github.com/Ozneeee")}
          >
            GitHub
          </span>
          <span className="mx-8">/</span>
          <span
            className="cursorBig"
            onClick={() =>
              window.open("https://www.linkedin.com/in/enzo-ruffin-a13ba0250/")
            }
          >
            Linkedin
          </span>
        </p>
        <p>43.60542487841573, 2.2420112103820076</p>
      </div>
    </div>
  );
};

export default Contact;
