import React from "react";
import AnimatedCursor from "react-animated-cursor";

const Cursor = () => {
  return (
    <AnimatedCursor
      color="241, 241, 241"
      innerScale={1}
      innerSize={20}
      innerStyle={{
        background: "none",
        border: 2,
        borderColor: "#f1f1f1",
        borderStyle: "solid",
        mixBlendMode: "difference",
      }}
      outerAlpha={1}
      outerSize={30}
      showSystemCursor={false}
      outerStyle={{
        mixBlendMode: "difference",
      }}
      clickables={[
        ".linkedin-icon",
        ".github-icon",
        ".Project1",
        ".Project2",
        ".Project3",
        ".Project4",
        ".SendMailBtn",
        ".cursorBig",
        {
          target: ".Project1",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
        {
          target: ".Project2",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
        {
          target: ".Project3",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
        {
          target: ".Project4",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
        {
          target: ".SendMailBtn",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
        {
          target: ".cursorBig",
          innerStyle: {
            background: "#f1f1f1",
            mixBlendMode: "difference",
          },
        },
      ]}
    />
  );
};

export default Cursor;
