import React, { useEffect, useState } from "react";

import LandingDesktop from "../components/LandingDesktop";
import LandingPhone from "../components/LandingPhone";

function Landing() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div>{dimensions.width >= 426 ? <LandingDesktop /> : <LandingPhone />}</div>
  );
}

export default Landing;
